@import '../../scss/variables';

.container {
    background-color: $yellow;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 3;
}

.containerInner {
    align-items: flex-start;
    display: flex;
    justify-content: center;
    padding-top: 14px;
    padding-bottom: 14px;
}

.richtext {
    flex-grow: 1;
    text-align: center;

    &,
    * {
        color: $grey;
        font-family: $sans-serif-medium;
        font-weight: $sans-medium;
        line-height: 28px;
    }

    strong {
        font-family: $sans-serif-bold;
        font-weight: $sans-bold;
    }
}

.close {
    background-color: transparent;
    border: none;
    flex-shrink: 0;
    font-size: 20px;
    line-height: 28px;
    margin-left: auto;
    margin-right: -18px;
    width: 64px;
}

@media (max-width: $mobile-larger) {
    .close {
        margin-right: -24px;
    }
}

@media (max-width: $mobile) {
    .close {
        margin-right: -16px;
        width: 48px;
    }
}